<template>
  <!-- 机票列表 改签单 -->
  <van-pull-refresh
    v-model="isLoading"
    @refresh="onRefresh"
    :disabled="!canRefresh||canRefreshChild"
  >
    <!-- 订单信息 -->
    <PaymentUI
      :canRefresh.sync="canRefreshChild"
      v-if="load"
      :order="order"
      :isChange="true"
      :peoList="peoList"
      :confirmLoading="confirmLoading"
      :cancelLoading="cancelLoading"
      :phone="phone"
      @refund="fetchData"
      @confirm="confirm"
      @cancel="cancel"
    />

    <div class="gap"></div>
    
    <!-- 人员信息 -->
    <PaymentPeoList
      :canRefresh.sync="canRefreshChild"
      :order="order"
      :peoList="peoList"
      :confirmLoading="confirmLoading"
      :cancelLoading="cancelLoading"
      @refund="fetchData"
      @confirm="confirm"
      @cancel="cancel"
    />

    <!-- 加载动画 -->
    <LoadingCircle :show="pageLoading"/>
  </van-pull-refresh>
</template>

<script>
import PaymentUI from "./components/PaymentUI";
import PaymentPeoList from "./components/PaymentPeoList";
import LoadingCircle from "@/components/LoadingCircle"
import {
  flightOrderItemReload,
  confirmFlightOrderChange,
  cancelFlightOrderChange
} from "@/api/aircraft";
import { fetchPhone } from "@/common/config.js";
export default {
  components: { PaymentUI, PaymentPeoList, LoadingCircle },
  data() {
    return {
      phone: "", // 服务商电话
      load: true,
      order: {}, // 订单
      peoList: [], // 人员
      confirmLoading: false, // 按钮转圈圈
      cancelLoading: false,
      isLoading: false, // 下拉刷新
      canRefresh: true, // 可否刷新
      canRefreshChild: true, // 可否刷新
      pageLoading: false, // 页面加载 
    };
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      setTimeout(() => {
        this.fetchData(false);
      }, 1000);
    },
    // ajax 获取数据
    fetchData(f = true) {
      if (f) this.canRefresh = true;
      this.phone = fetchPhone(this.order.corp); // 更新服务商电话
      if (this.canRefresh)
        flightOrderItemReload({
          orderNo: this.order.orderNo || this.order.orderSerialNo,
          outOrderNo: this.order.outOrderNo,
          corp: this.order.corp
        }).then(response => {
          this.order = response.order;
          let peos = response.orderItem;
          peos.forEach(element => {
            let id = element.identityNo;
            let insuranceList = response.insuranceOrderItems || []; // 防空值
            let res = insuranceList.filter(e => {
              return e.identityNo == id;
            });
            element.insurance = res;
          });
          this.peoList = peos;
          this.isLoading = false;
          this.pageLoading = false;
          this.canRefresh = false;
          setTimeout(() => {
            this.canRefresh = true;
          }, 30 * 1000);
        })
        .catch(()=>{
          this.pageLoading = false;
        })
      else {
        this.$notify({
          type: "warning",
          message: "请勿频繁刷新，请30秒后再次尝试。"
        });
        this.isLoading = false;
      }
    },
    // 出票
    confirm() {
      this.confirmLoading = true;
      confirmFlightOrderChange({
        orderNo: this.order.orderSerialNo,
        outOrderNo: this.order.outOrderNo,
        originalOrderNo: this.order.bz3,
        corp: this.order.corp
      })
        .then(response => {
          // 如果经费不足提示。
          if (!response.success) {
            this.confirmLoading = false;
            return this.$toast.fail(response.msg);
          }
          this.$toast.success("出票成功");
          this.fetchData();
          this.confirmLoading = false;
        })
        .catch(() => {
          this.confirmLoading = false;
        });
    },
    // 取消出票
    cancel() {
      this.$dialog.confirm({
        title: '提示',
        message: '是否要取消订单？',
      })
        .then(() => {
        this.cancelLoading = true;
        cancelFlightOrderChange({
          orderNo: this.order.orderSerialNo,
          outOrderNo: this.order.outOrderNo,
          originalOrderNo: this.order.bz3,
          corp: this.order.corp
        })
          .then(response => {
            this.$toast.success("取消成功");
            this.fetchData();
            this.cancelLoading = false;
          })
          .catch(() => {
            this.cancelLoading = false;
          });
        })
    }
  },
  watch: {
    $route(val) {
      this.order = this.$route.query;
      this.fetchData();
    }
  },
  activated() {
    this.peoList = [];
    this.order = {};
    this.pageLoading = true;
    this.order = this.$route.query;
    this.fetchData();
  }
};
</script>